* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.deferImg {
  display: none;
}

header {
  height: 360px;
  padding-top: 40px;
  max-width: 1600px;
  margin: 0 auto;
}

nav {
  max-width: 95%;
  margin: auto;
}

header nav a {
  text-decoration: none;
  color: #000;
  text-align: center;
  display: block;
  padding-bottom: 2px;
  margin-bottom: 15px;
}

header nav a:hover {
  text-decoration: underline;
  padding-top: 2px;
  padding-bottom: 0;
}

.logoImg {
  max-width: 500px;
  min-width: 300px;
  min-height: 300px;
  max-height: 300px;
  margin: auto;
  display: block;
}

picture img {
  width: 100%;
}

.home.row > a {
  padding: 0;
  overflow: hidden;
  height: 0;
  padding-bottom: 18.75%;
}

.home img {
  width: 100%;
  margin: 0;
  transition: all 0.3s ease-in-out;
}
.home a:hover img {
  width: 120%;
  margin: -10%;
}
.home a {
  text-decoration: none;
}
.home a span.title {
  margin-top: -20%;
  display: block;
  text-align: center;
  color: #fff;
  font-size: 4rem;
  text-shadow: -3px 3px 7px #000;
  transition: all 0.3s ease-in-out;
}
.home a:hover span.title {
  margin-top: -10%;
}

main {
  min-height: 50vh;
  min-height: calc(100vh - 660px);
}
main.home {
  margin: 2rem 0.5rem;
}

main .wrapper {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

footer {
  background-color: #333;
  padding: 20px;
  color: #fff;
}

footer a {
  color: #fff;
  display: block;
  padding: 15px;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

.hero {
  max-height: 500px;
  overflow: hidden;
}

main p {
  line-height: 30px;
  margin-bottom: 30px;
}

.blogs picture img {
  max-width: 320px;
  max-height: 215px;
  float: left;
  margin-right: 20px;
}

.blogs article {
  color: #000;
  padding: 15px;
  background-color: #eee;
  min-height: 248px;
  border: 1px solid #8888;
  margin-bottom: 20px;
}
.blogs article picture img {
  display: inline-block;
}
.blogs article > a {
  color: #000;
  text-decoration: none;
  display: block;
}
.blogs article legend {
  font-size: 24px;
}
.blogs article p {
  font-size: 18px;
}
.blogs article .info {
  clear: both;
  padding-top: 20px;
}
.info .date {
  display: inline-block;
}
.blogs article a:hover legend {
  text-decoration: underline;
}

.tags {
  float: right;
}
.tags a.tag {
  color: #000;
  text-decoration: none;
  background-color: #ccc;
  padding: 6px;
  border-radius: 6px;
  margin-left: 10px;
}
.tags a.tag:hover {
  background-color: #aaa;
}

ul.share-buttons {
  list-style: none;
  padding: 0;
}
ul.share-buttons li {
  display: inline;
}
ul.share-buttons .sr-only {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.share-buttons a {
  color: #333;
  margin-right: 10px;
}

picture.expanded {
  background: #888a;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
}
picture.expanded img {
  position: absolute;
  height: unset;
  top: 0;
  left: 0;
}

.map a {
  display: block;
  margin-bottom: 10px;
  color: #000;
  text-decoration: none;
}

@media (max-width: 1023px) {
  .home span.title {
    font-size: 3rem;
  }
  .home.row > a {
    padding-bottom: 28.125%;
  }
}

@media (max-width: 767px) {
  .blogs article picture img {
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: inherit;
    float: none;
  }
  .blogs article legend {
    margin-top: 15px;
  }
  .home.row > a {
    padding-bottom: 56.25%;
  }
}
